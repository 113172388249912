const assign = require('object-assign');
const delegate = require('./delegate');
const delegateAll = require('./delegateAll');

const DELEGATE_PATTERN = /^(.+):delegate\((.+)\)$/;
const SPACE = ' ';

const getListeners = function(type, handler) {
  var match = type.match(DELEGATE_PATTERN);
  var selector;
  if (match) {
    type = match[1];
    selector = match[2];
  }

  var options;
  if (typeof handler === 'object') {
    options = {
      capture: popKey(handler, 'capture'),
      passive: popKey(handler, 'passive')
    };
  }

  var listener = {
    selector: selector,
    delegate: (typeof handler === 'object')
      ? delegateAll(handler)
      : selector
        ? delegate(selector, handler)
        : handler,
    options: options
  };

  if (type.indexOf(SPACE) > -1) {
    return type.split(SPACE).map(function(_type) {
      return assign({type: _type}, listener);
    });
  } else {
    listener.type = type;
    return [listener];
  }
};

var popKey = function(obj, key) {
  var value = obj[key];
  delete obj[key];
  return value;
};

module.exports = function behavior(events, props) {
  const listeners = Object.keys(events)
    .reduce(function(memo, type) {
      var listeners = getListeners(type, events[type]);
      return memo.concat(listeners);
    }, []);

  return assign({
    add: function addBehavior(element) {
      listeners.forEach(function(listener) {
        element.addEventListener(
          listener.type,
          listener.delegate,
          listener.options
        );
      });
    },
    remove: function removeBehavior(element) {
      listeners.forEach(function(listener) {
        element.removeEventListener(
          listener.type,
          listener.delegate,
          listener.options
        );
      });
    }
  }, props);
};
